.tabela-pendencias {
  transition: height 0.3s ease, opacity 0.3s ease, margin 0.3s ease;
  height: auto;
  opacity: 1;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tabela-pendencias.desabilitada {
  height: 0;
  opacity: 0;
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
}

.titulo-pendencias {
  color: #13284A;
  font-size: 60px;
  text-align: center;
  display: block;
  margin: auto;
  padding: 1rem;
}

.container-grid-atividades-revisao .check-revisao-validacao .jqx-button{ 
  font-size: smaller;
  padding-bottom: 0;
  border-left-style: solid;
  border-right-width: 1px;
  border-color: #c7c7c7 #c7c7c7 #e8e8e8 #c7c7c7;
  margin-bottom: 0px !important;
  border-radius: 20px 20px 0px 0px;
  background-color: #e8e8e8;
  color: black;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}