.menuLateral > .icon {
    font-size: x-large;
    color:white;
}

.menuLateral > .i > .icon > .content::before  {
    border: 2px solid white;
}
.botaoSair{
    color: #F0F0F0;
    border:#F0F0F0;
    background-color: #03265f00;
}
.ajudaSair {
    display: inline-block;
}
.bemVindo {
    color:#0079c1 !important;
    width: 100%;
    text-align: center;
    padding-top: 2%;
    font-size: 48px;
}
.texto {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
}
.textoOla {
    font-size:16px;
    width: 100%;
    background-color: #fff;
    text-align: justify;
    border-radius: 8px !important;
    padding: 2rem !important;
}
.paragrafo {
    padding: 1%;
    color: #707070;
}
.textoNegrito {
    padding: 1%;
    font-size:16px;
    font-weight:900;
}

.botoesIconeTexto {
    height: 200px;
    width: 200px;
    background-color: #071D41;
    color: #fff;
    margin: 5px;
    border: none;;
}
.botoesIconeTexto:hover {
    background-color: #0079C1;
}
.botoesIcones{
    height: 132px;
    width: 132px;
}

.botoesTexto{
    font-size: 12px;
}
#mostrarDesenvolvimento{
    display: none;
}
  
.botoesIconeTexto:hover #mostrarDesenvolvimento{
display:block;
}
.botoesIconeTexto:hover #mostrarTexto{
    display:none;
}


.menuContainer {
    border: 2px solid white;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 3px;
    padding-left: 7px;
    border-radius: 5px 5px 5px 5px;
    background-color: #03265f;
    z-index: 20;
}

.item {
    height: 30px !important;
    border-radius: 0px;
    text-decoration: none !important;
}

.item > .text {
    font-size: 14px;
}

.menuLateral > .ui.dropdown .menu{
    border-radius: 0px !important;
}

.nome{
    color : #082767;
    width: 100%;
    text-align: center;
    padding: 0px;
}
.cargo{
    color: #082767;
    width: 100%;
    text-align: center;
    padding: 0px;
    font-style: italic;
    font-size: small;
    margin-bottom: 10px;
}

.modal-title {
    color : #082767;
    width: 100%;
    text-align: center;
    padding: 0px;
}

.h4, h4 {
    font-size: 1.99rem;
}
.caixa_lov {
    border: 1px solid #082767;
    margin: 0 2px;
    background: #F0F0F0;
}

.novoMenu {
    position: relative;
    z-index: 1000;
}