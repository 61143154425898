.modal-content .modal-motivo-reavaliacao-toolbar{
    margin-top: 10px;
}

.modal-content  .modal-motivo-reavaliacao-body {
    width: 98%;
}

.modal-content  .modal-motivo-reavaliacao-body-container {
    padding-top: 0px;
}

.modal-content  .modal-motivo-reavaliacao-header-container {
    padding-bottom: 0px;
    padding-top: 10px;
    border-bottom: none;
    margin: 0px;
    padding-left: 0px;
    padding-right: 10px;
}