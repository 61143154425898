/* este CSS não é exatamente responsivo, mas responsividade não é requisito neste caso */

.file-grid {
    background-color: #ebebeb;
    border: 2px dashed grey;
    border-radius: 4px;
}

.file-grid__drop-zone {
    padding: 23px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.file-grid__files {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
    gap: 1em;
    padding: 0 15px;
}

.file-grid__text {
    font-size: 12px;
    margin: 0;
}

.file-grid__instructions p {
    text-align: center;
    margin: 0;
}

.file-grid__instructions + .file-grid__files {
    margin-top: 15px;
}

.file-grid__files + .file-grid__instructions {
    /*margin-top: 15px;*/
}
