.info-container {
    display: flex;
    justify-content: center;
    margin: 0.5em 0 1.5em 0;
    padding: 0px;
    gap: 10px;
}

.info-item {
    margin: 0px;
    padding: 0px;
    color: var(--color-primary-default);
}

.info-divider {
    width: 2px;
    height: auto;
    margin-top: 5px;
    background-color: var(--color-primary-default);
    margin-top: 5px;
}
