.legenda-mapa .borda-legenda{
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;        
}

.legenda-mapa .ponto-gps{
    transform:rotate(45deg)    
}


.legenda-mapa .ponto-gps-selecionado, .ponto-gerado-selecionado{
    color:rgb(235, 111, 230)
}

.legenda-mapa .ponto-gps-nao-selecionado, .ponto-gerado-nao-selecionado{
    color:rgba(121, 241, 88, 0.5)
}

.legenda-mapa .amostra{
    color:rgba(246, 143, 52, 1)
}

.legenda-mapa .amostra-historica{
    color:rgba(255, 0, 0, 1)
}

.legenda-mapa .amostra-editada{
    color:rgba(121, 241, 88, 1)
}

.legenda-mapa .geometria-projeto{
    color:rgba(0, 255, 128, 1)
}