.aflora-number-input {
    width: 100%;
    line-height: 19px;
    /*height: 21px;*/
    padding-left: 3px;
    padding-right: 3px;
    /*vertical-align: middle;*/
    font-size: 13px;
    appearance: none;

    /*color: #555555;*/
    /*background-color: #ffffff;*/
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border linear 0.2s, box-shadow linear 0.2s;
    border: solid 1px #ccc;
    border-radius: 4px;
}

.aflora-field-latitude {
    width: 100%;
}

.aflora-field-longitude {
    width: 100%;
}

.aflora-selecao {
    width: 98%;
}

.aflora-number-input:focus {
    border-color: rgba(82, 168, 236, 0.8);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(82, 168, 236, 0.8);
}

.aflora-number-input:disabled {
    background-color: unset;
    border-color: rgb(204, 204, 204);
}

.aflora-number-input-container {
    height: 21px;
}

.aflora-number-input::first-line {
    vertical-align: middle;
}
