.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 8px;
}

.registros-validados {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  justify-content: center;
}

.progress-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.registros-publicados {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-right {
  display: flex;
  align-items: center;
  width: 60%;
  justify-content: center;
  flex-direction: column;
  margin-right: 5%;
  height: auto;
}

.progress-label {
  color: #0093dd;
  margin-top: 1px;
  font-size: 8px;
  font-weight: bold;
  align-items: flex-end;
  justify-content: flex-end;
}

.custom-progress-bar {
  width: 100%;
  height: 17px;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 1px;
  background-color: #E8E8E8;
}


.progress-container progress::-webkit-progress-value {
  background-color: #0064b4;
}

.container-grid-atividades-publicar .check-revisao-publicacao .jqx-button {
  font-size: smaller;
  padding-bottom: 0;
  border-left-style: solid;
  border-right-width: 1px;
  border-color: #c7c7c7 #c7c7c7 #e8e8e8 #c7c7c7;
  margin-bottom: 0px !important;
  border-radius: 20px 20px 0px 0px;
  background-color: #e8e8e8;
  color: black;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}