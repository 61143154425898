.mapa-atitude {
    width: 100px;
    height: 100px;
    border: 0;
}
.mapa-atitude.grande {
    width: 100%;
    height: 300px;
}
.mapa-atitude.grande canvas {
    width: 100% !important;
    height: 300px !important;;
}
