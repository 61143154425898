.s-alert-box {
    min-width: 250px;
}

.s-alert-close::before,
.s-alert-close::after {
    width: 2px;
}

.jqx-fill-state-pressed {
    background-color: #1351b459 !important;
}

.checkbox-disable-odd {    
    .jqx-fill-state-normal {
        border-color: #ffffff;
        background: #ffffff;
        cursor: default;
    }
}
.checkbox-disable-even {    
    .jqx-fill-state-normal {
        border-color: #f9f9f9;
        background: #f9f9f9;
        cursor: default;
    }
}

.status-validacao-detalhe{
    margin-top: 8px;
}

.swal-text {
    max-height: 50vh;
    overflow: auto;
}