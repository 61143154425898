.etiquetaAmostra {
  border: 2px solid black;
  border-radius: 30px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
}
