body {
    background-color: #f0f0f0;
    height: 100%;
	margin: 0;
}
@font-face {
	font-family:'Roboto';
	src: url('../../assets/font/Roboto-Regular.ttf');
}
@font-face {
	font-family:'RobotoBold';
	src: url('../../assets/font/Roboto-Bold.ttf');
}

.bg {
    background: url("../../assets/img/background.png") no-repeat center!important;
	background-size: cover!important;
    height: 100vh!important;
}
.login-card {
	position: relative!important;
	top: 30%!important;
	margin: auto!important;
	padding: 40px!important;
	max-width: 380px!important;
	background-color: #f7f7f7!important;
	border-radius: 2px!important;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3)!important;
	overflow: hidden!important;	
}
.login-card h1 {
	text-align: center!important;
	margin: 10px 0 35px 0!important;
}
.login-card input[type=text], input[type=password] {
	font-family: 'Roboto', sans-serif!important;
	font-size: 14x!important;
	height: 30px!important;
	width: 100%!important;
	margin-bottom: 10px!important;
	padding: 0 8px!important;
	background: #fff!important;
	border: 1px solid #d9d9d9!important;
	border-top: 1px solid #c0c0c0!important;
	box-sizing: border-box!important;
	-moz-box-sizing: border-box!important;
	box-shadow: inset 0 1px 2px rgba(0,0,0,0.1)!important;
	-moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1)!important;
	-webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1)!important;
}
.login-card input[type=text]:hover, input[type=password]:hover {border-top: 1px solid #a0a0a0!important;}
.login-card input[type=submit] {
	width: 100% !important;
	margin-bottom: 10px!important;
}
.login-card input[type=submit]{
	font-family: 'RobotoBold', sans-serif!important;
	font-size: 17px!important;
	background-color: #5AC2E3!important;
	color: #fff!important;
    border: 0!important;
	text-shadow: 0 1px rgba(0,0,0,0.1)!important;
	height: 36px!important;
	cursor: pointer!important;
}
.login-card input[type=submit]:hover {background-color: #0F97A9!important;}





.login .form-control {
    background-color: #fffcc8 !important;
}

.jqx-button {
    font-size: 16.8px;
    font-weight: 500;
}

.arcgis .jqx-button {
    background-color: #fff !important;
    border-color: #0079c1 !important;
    color: #0079c1 !important;
}

.login-with-google-btn {
	transition: background-color .3s, box-shadow .3s;
	padding: 12px 16px 12px 42px;
	border: none;
	border-radius: 3px;
	box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
	width: 100%;
	color: #757575;
	font-size: 14px;
	font-weight: 500;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
	
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
	background-color: white;
	background-repeat: no-repeat;
	background-position: 16px 13px;
}
.login-with-google-btn:hover {
	box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.login-with-google-btn:active {
	background-color: #eeeeee;
}

.login-with-google-btn:focus {
	outline: none;
	box-shadow: 
	0 -1px 0 rgba(0, 0, 0, .04),
	0 2px 4px rgba(0, 0, 0, .25),
	0 0 0 3px #c8dafc;
}
.google {
	margin-top: 20px;
	text-align: center
}