#ModalNavBar { 
	height: 100px;
	margin-bottom: 25px;
}

#topo1 {
	/* background: #869193; */
	background: #ffffff !important;
	width: 100%;
    height: 175px;
    left: 0;
    top: 0;
	position: absolute;
}

/* exibição inicial */
.logos-cabecalho {
	flex: 1;
	width: 100%;
	height: 100%;
	justify-content: space-around;
	align-items: 'center';
	display: flex;
}

.logo-container {
	flex: 1;
	display: flex;
	align-items: center;
	margin: 0 20px;
	max-width: 25%;
	overflow: hidden;
}

.logo-container img { 
	max-height: 150px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

/* 5 - SGIH Sistema de Gerenciamento de Informações Hidrométricas */
.logo-sgih {
	margin: 0;
}

.logo-sgih img {
	max-height: 137px;
}

.logo-cabecalho-img {
	margin-left: 30px !important;
	margin-right: 30px !important;
	max-width: 90%;
    max-height: 90%;
}