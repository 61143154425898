.card {
    border-radius: 8px !important;
    box-shadow: 1px 1px 3px 0px;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.card:hover {
    box-shadow: 4px 6px 8px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 25%);
}

.card-body {
    padding: 2rem !important;
}

