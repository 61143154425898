@charset "utf-8";
/* CSS Document */

@font-face {
	font-family: 'OpenSans';
	src: url('../font/OpenSans-Regular.ttf');
}

/* CSS Reset */

*, *:before, *:after {
	/* font-family: 'OpenSans';
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing:border-box;
	box-sizing: border-box;
	margin:0;
	padding:0;
	border:0; */
}

html {
	line-height: 1.3;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
	display: block;
}

audio, video {
	display: inline-block;
}

a {
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
	outline: 0;
}

img {
	border-style: none;
	-ms-interpolation-mode: bicubic;
	image-rendering: optimizeQuality;
}

a[href], input[type=image], select, button, input[type=button], input[type=reset], input[type=submit] {
	cursor: pointer;
}

button, input {
	overflow: visible;
}

button, select {
	text-transform: none;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
	outline: 1px dotted ButtonText;
}

/* Login */

html {
	min-height: 100%;
	position: relative;
	overflow-x: hidden;
}

body {
	font-family: 'OpenSans' !important;
	font-size: 100%;
	overflow-x: hidden;
}

@media (min-width: 1200px) {
	.container {
		max-width: 1500px !important;
	}
}

.flex-display {
	display: flex;
}

.mouseBlock, .mouseBlock>div, .mouseBlock>div>input {
	cursor: no-drop !important;
}

.form-control {
	font-size: 14px
}

.form-control:focus {
	border-color: #9EA8A8;
	box-shadow: 0 0 0 0.2rem rgba(158, 168, 168, 0.26);
}

.form-label {
	font-size: 16px !important;
}

.h5 {
	font-size: 29.036px !important;
}

.logoPrincipal {
	margin-top: -7%;
	max-height: 600px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 50%;
}

.ajuda-campo {
	margin-left: -4px;
	color: black;
	font-size: 8px !important;
}

.jqx-icon-arrow-up, .jqx-icon-arrow-down {
	/* display: none !important; */
}

.jqx-grid-column-header .jqx-icon-arrow-up, .jqx-grid-column-header .jqx-icon-arrow-down {
	display: block !important;
}

.react-tabs {
	-webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
	border-bottom: 1px solid #aaa;
	margin: 0 0 10px;
	padding: 0;
}

.react-tabs__tab {
	display: inline-block;
	border: 1px solid transparent;
	border-bottom: none;
	bottom: -2px;
	position: relative;
	list-style: none;
	padding: 8px 14px;
	cursor: pointer;
	background-color: #fff;
	font-size: 16px;
	color: #555555;
	font-weight: 600;
}

.react-tabs__tab--selected {
	background: #C5D4EB;
	color: black;
	border-bottom: 3px solid #1351B4;
}

.react-tabs__tab--disabled {
	color: #ddd;
	cursor: default;
}

.react-tabs__tab:focus {
	box-shadow: 0 0 5px hsl(208, 99%, 50%);
	border-color: hsl(208, 99%, 50%);
	outline: none;
}

.react-tabs__tab:focus:after {
	content: "";
	position: absolute;
	height: 5px;
	left: -4px;
	right: -4px;
	bottom: -5px;
	background: #fff;
}

.react-tabs__tab-panel {
	display: none;
}

.react-tabs__tab-panel--selected {
	display: block;
}

.jqx-button {
	background-color: #1351B4;
}

.tipo_ilustracao {
	margin-bottom: 125px !important;
}