/* 
.App {
    text-align: center;
    margin: 20px;
  }
  
  .App .forms {
    padding-left: 50px;
    text-align: left
  }
  
  .App iframe {
    width: 900px;
    height: 600px;
  } */

.mapa {
  width: 100%;
  height: 600px;
  border: 0;
}

.col-mapa-atividades iframe{
  height: 190px;
}

.row-mapa-atividades iframe{
  height: 380px;
}
