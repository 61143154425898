#logo {
    background: url(../../assets/img/logo_base_geo_interno.png) no-repeat left center;
    width: 220px;
    height: 205px;
    background-size: contain;
}

.navLinks {
    margin-top: 110px;
}

.link-white {
    color: #fff;
}

.link-white:hover {
    color: #fff;
}

#navBar-completo {
    height: 245px;
    width: 100%;
}

#topo1-completo {
    background-image: url(../../assets/img/background_topo.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 175px;
    padding: 1% 0;
}

#topo1-completo #logo {
    margin: 0 0 0 5%;
    max-height: 100%;
}

#topo1-completo p {
    margin: 0;
}

#topo2-completo {
    background: #071D41;
    text-align: center;
    width: 100%;
    height: 80px;
    padding: 15px;
    padding-left: 5%;
    padding-right: 10%;
    display: flex;
    align-items: center;
    color: #fff;
}

#topo2-completo p {
    padding: 0;
    margin: 0;
}

#topo2-completo .header-info {
    font-size: 12px;
}

#topo2-completo h2 {
    margin: 0 auto;
    color: #fff;
    font-size: 34.832px;
}

.botao-sair {
    color: #6c757d;
    font-size: 1.3rem;
    cursor: pointer;
}