.seletor-coordenadas-hemisferio {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  min-height: 60px;
  max-width: 200px;
  overflow: hidden !important; /* Se a largura da página for muito pequena, evitar que apareça um scroll */
}

.seletor-coordenadas-hemisferio > .jqx-radiobutton {
  position: absolute;
  overflow: hidden !important; /* Se a largura da página for muito pequena, evitar que apareça um scroll */
}

/* Sul */
.seletor-coordenadas-hemisferio > .jqx-radiobutton:nth-of-type(1) {
  bottom: 0;
  left: 35%;
  position: absolute;
  overflow: hidden !important; /* Se a largura da página for muito pequena, evitar que apareça um scroll */
}

/* Norte */
.seletor-coordenadas-hemisferio > .jqx-radiobutton:nth-of-type(2) {
  top: 0;
  left: 35%;
  position: absolute;
  overflow: hidden !important; /* Se a largura da página for muito pequena, evitar que apareça um scroll */
}

/* Leste */
.seletor-coordenadas-hemisferio > .jqx-radiobutton:nth-of-type(3) {
  left: calc(70% - 15px);
  position: absolute;
  overflow: hidden !important; /* Se a largura da página for muito pequena, evitar que apareça um scroll */
}

/* Oeste */
.seletor-coordenadas-hemisferio > .jqx-radiobutton:nth-of-type(4) {
  left: 15px;
  position: absolute;
  overflow: hidden !important; /* Se a largura da página for muito pequena, evitar que apareça um scroll */
}

.seletor-coordenadas-hemisferio .erro-campo {
  position: absolute;
  left: 15px;
  bottom: -15px;
  overflow: hidden !important; /* Se a largura da página for muito pequena, evitar que apareça um scroll */
}

